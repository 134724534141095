import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import SEO from "../components/seo";
import OrderHistory from "../components/orderHistory/orderHistoryList";
import { LINKS } from "../constants/links";
import { Loading } from "../components/common/loading";
import { fetchOrderHistoryAction } from "../state/orderHistory.reducer";
import AppButton from "../components/common/button";
import { AppContainer } from "../components/common/appContainer";

const StyledWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 0 30px 30px;
`;
const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlack};
	font-size: 1.7rem;
	font-weight: 300;
	line-height: 2rem;
	padding: 25vh 0;
	text-align: center;
`;

const OrderHistoryPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const orderHistory = useSelector((state) => state.orderHistory);
	const { user } = useSelector((state) => state.user);
	const { data: history, loading } = orderHistory;

	useEffect(() => {
		dispatch(fetchOrderHistoryAction(user.id));
	}, []);

	return (
		<AppContainer>
			<SEO title={t("orderHistory.title")} />
			{loading && <Loading text={t("orderHistory.historyLoading")} />}
			{history.length > 0 && <OrderHistory history={history} />}
			{history.length === 0 && (
				<StyledWrapper>
					<StyledText>{t("orderHistory.notFoundText")}</StyledText>
					<AppButton as={Link} to={LINKS.HOMEPAGE}>
						{t("common.homepage")}
					</AppButton>
				</StyledWrapper>
			)}
		</AppContainer>
	);
};

export default OrderHistoryPage;
