import React from 'react';
import styled from 'styled-components';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { LINKS } from '../../constants/links';
import { EnumPaymentStatus } from '../../constants/paymentStatuses';

const StyledLinkWrapper = styled(Link)`
  text-decoration: none;
  &:last-child > div {
    > div {
      border-bottom: none;
    }
  }
`;
const StyledRestaurantItemWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px 0 25px;
  position: relative;
  text-decoration: none;
  &:before {
    background-color: rgba(107, 37, 123, 1);
    border-radius: 50%;
    content: '';
    left: 0;
    opacity: 0.14;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.4s, opacity 0.4s, padding 0.4s;
    z-index: 1;
  }
  &.active:before {
    padding: 120%;
  }
`;
const StyledRestaurantImage = styled.div`
  background-image: url('${({ bgImg }) => bgImg}');
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  min-width: 64px;
  width: 64px;
`;
const StyledTextWrapper = styled.div`
  flex-grow: 1;
  padding: 0 20px;
`;
export const StyledRestaurantName = styled.h2`
  color: ${({ theme }) => theme.colors.brandPurple};
  display: flex;
  font-size: 1.15rem;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.5rem;
  margin: 0;
`;
export const StyledRestaurantAddress = styled.h2`
  color: ${({ theme }) => theme.colors.fontGray};
  font-size: 0.86rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0 0 8px;
`;
const StyledRestaurantHours = styled.h2`
  color: ${({ theme, isError }) => (isError ? theme.colors.fontRed : theme.colors.brandBlue)};
  color: ${({ theme, isProcessing }) => (isProcessing ? theme.colors.borderGray : theme.colors.brandBlue)};
  font-size: 0.86rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
`;
const StyledRestaurantPriceWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const StyledPrice = styled(StyledRestaurantAddress)`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-weight: bold;
`;

const OrderHistoryListItem = ({ item }) => {
	console.log(item);
	const { t } = useTranslation();
	const {
		total, tip, paymentStatus, currency, restaurant, updatedAt,
	} = item;
	const miniatureImage = restaurant?.images?.find((image) => image.type === 'MINIATURE')?.url;
	const statusDate = moment(updatedAt).format('DD/MM/YYYY, HH:ss');
	const isError = paymentStatus === EnumPaymentStatus.FAILURE;
	const isProcessing = paymentStatus === EnumPaymentStatus.PENDING;
	const onClick = (e) => {
		e.currentTarget.className = `${e.currentTarget.className} active`;
	};
	return (
		<StyledLinkWrapper to={LINKS.ORDER + item.id}>
			<StyledRestaurantItemWrapper onClick={onClick}>
				<StyledRestaurantImage bgImg={miniatureImage} />
				<StyledTextWrapper>
					<StyledRestaurantName>{restaurant?.name}</StyledRestaurantName>
					<StyledRestaurantAddress>{restaurant?.localization?.name}</StyledRestaurantAddress>
					<StyledRestaurantHours
						isError={isError}
						isProcessing={isProcessing}
					>
						{t(`paymentStatus.${paymentStatus.toLowerCase()}`)}
						{' '}
						{statusDate}
					</StyledRestaurantHours>
				</StyledTextWrapper>
				<StyledRestaurantPriceWrapper>
					{total && (
						<StyledPrice>
							{((total + tip) / 100).toFixed(2)}
							&nbsp;
							{currency}
						</StyledPrice>
					)}
				</StyledRestaurantPriceWrapper>
			</StyledRestaurantItemWrapper>
		</StyledLinkWrapper>
	);
};

export default OrderHistoryListItem;
