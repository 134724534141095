import React from 'react';
import styled from 'styled-components';
import OrderHistoryListItem from './orderHistoryListItem';

const StyledHistoryWrapper = styled.div`
  padding: 0;
`;

const OrderHistory = ({ history }) => (
	<StyledHistoryWrapper>
		{history && history.map((historyItem) => (
			<OrderHistoryListItem
				key={historyItem.id}
				item={historyItem}
			/>
		))}
	</StyledHistoryWrapper>
);

export default OrderHistory;
